import * as React from "react"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from 'gatsby'
import {Col, Container, Row, Ratio} from 'react-bootstrap'

import ArrowRight from '../../static/arrow-left.png';
import MapIcon from '../../static/map.png';
import VarmaIcon from '../../static/varma-icon.svg';

import Moment from 'moment';

import {Trans} from 'gatsby-plugin-react-i18next';

const IndexPage = ({ data }) => {
  var home = data.allContentfulHomePage.edges[0].node
  var videourl = home.heatPowerVideoLink.replace("watch?v=", "embed/");
  var Project1 = data.allContentfulProject.edges[0].node
  var Project2 = data.allContentfulProject.edges[1].node
  var Project3 = data.allContentfulProject.edges[2].node
  var News1 = data.allContentfulNews.edges[0].node
  var News2 = data.allContentfulNews.edges[1].node
  var News3 = data.allContentfulNews.edges[2].node

  return (
    <Layout front='true' title="Varmaorka">
      <div className="page-banner text-white position-relative">
        <GatsbyImage objectFit="cover" objectPosition="50% 50%" image={home.topImage.gatsbyImageData} alt={home.topImage.title} />
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={12} lg={12} className="align-items-center justify-content-center">
              <div className="front-container">
                <img className="logo-front" src='../../varmaorkaWhite.svg' alt="varmaorka icon" />
                <div className="my-box">
                  <h1 className="text-uppercase my-text">{home.heroSubTitle}</h1>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-100">
        <Container>
          <Row className="align-items-center mb-md-5 mb-4">
            <Col md={8}>
              <h2 className="section-title">{home.aboutSectionHeading}</h2>
            </Col>
            <Col md={4} className="text-end">
              <Link to={'/about'} className="btn-link"><Trans>More about us</Trans><img src={ArrowRight} alt="arrow-left" /></Link>
            </Col>
          </Row>
          
          <Row className="mb-3">
            <Col sm={4} md={2} lg={1} className="pt-md-2">
              <h3>{home.whyHeading}</h3>
            </Col>
            <Col sm={8} md={6} lg={6}>
              <p className="vh_line">{home.whyText.whyText}</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={4} md={2} lg={1} className="pt-md-2">
              <h3>{home.howHeading}</h3>
            </Col>
            <Col sm={8} md={6} lg={6}>
              <p className="vh_line">{home.howText.howText}</p>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={4} md={2} lg={1} className="pt-md-2">
              <h3>{home.whatHeading}</h3>
            </Col>
            <Col sm={8} md={6} lg={6}>
              <p className="vh_line">{home.whatText.whatText}</p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* /.py-100 */}

      <div className="py-80">
        <Container>
          <Row className="align-items-center mb-lg-5 mb-4">
            <Col md={12}>
              <h2 className="section-title">{home.heatPowerSectionHeading}</h2>
            </Col>
          </Row>
          {/* <iframe src={videourl}></iframe> */}
          <Ratio aspectRatio="16x9" className="mb-5">
            <embed type="image/svg+xml" src={videourl} />
          </Ratio>

          <Row>
            <Col md={6} className="mb-md-0 mb-4">
              <p className="vh_line">{home.heatPowerText1.heatPowerText1}</p>
            </Col>
            <Col md={6} className="mb-md-0 mb-4">
              <p className="vh_line">{home.heatPowerText2.heatPowerText2}</p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* /.py-80 */}

      <div className="py-80">
        <Container>
          <Row className="align-items-center mb-4">
            <Col md={12}>
              <h2 className="section-title"><Trans>Projects</Trans></h2>
            </Col>
          </Row>
          

          <Row>
            <Col md={4} className="mb-5">
              <Link to={"projects/" + Project1.slug}>
                <div className="card-image card-image-lg">
                  <GatsbyImage image={Project1.image.gatsbyImageData} alt={Project1.image.title} />
                  <div className="card-image-overlay">
                    <p>{Project1.locationLatlong} <img src={MapIcon} alt="map icon" /></p>
                    <h3>{Project1.name}</h3>
                    <p>{Project1.startedText} <br />{Project1.finishedText}</p>
                  </div>
                </div>
              </Link>
            </Col>
            <Col md={4} className="mb-5">
              <Link to={"projects/" + Project2.slug}>
                <div className="card-image card-image-lg">
                  <GatsbyImage image={Project2.image.gatsbyImageData} alt={Project2.image.title} />
                  <div className="card-image-overlay">
                    <p>{Project2.locationLatlong} <img src={MapIcon} alt="map icon" /></p>
                    <h3>{Project2.name}</h3>
                    <p>{Project2.startedText} <br />{Project2.finishedText}</p>
                  </div>
                </div>
              </Link>
            </Col>
            <Col md={4} className="mb-5">
              <Link to={"projects/" + Project3.slug}>
                <div className="card-image card-image-lg">
                  <GatsbyImage image={Project3.image.gatsbyImageData} alt={Project3.image.title} />
                  <div className="card-image-overlay">
                    <p>{Project3.locationLatlong} <img src={MapIcon} alt="map icon" /></p>
                    <h3>{Project3.name}</h3>
                    <p>{Project3.startedText} <br />{Project3.finishedText}</p>
                  </div>
                </div>
              </Link>
            </Col>            
            <Col md={12} className="text-end">
              <Link to={'/projects'} className="btn-link"><Trans>All Projects</Trans><img src={ArrowRight} alt="arrow-left" /></Link>
            </Col>
          </Row>
        </Container>
      </div>
      {/* /.py-80 */}


      <div className="py-80 pt-0">
        <Container>
          <Row className="align-items-center mb-4">
            <Col md={12}>
              <h2 className="section-title"><Trans>News</Trans></h2>
            </Col>
          </Row>
          

          <Row>
            <Col md={4} className="mb-5">
              <Link to={"news/" + News1.slug}>
                <div className="card-image card-image-sm">
                  <GatsbyImage image={News1.image.gatsbyImageData} alt={News1.image.title} />
                  <div className="card-image-overlay">
                    <p>{Moment(News1.date).format('ll')}</p>
                    <h3>{News1.title}</h3>
                  </div>
                </div>
              </Link>
            </Col>
            <Col md={4} className="mb-5">
              <Link to={"news/" + News2.slug}>
                <div className="card-image card-image-sm">
                  <GatsbyImage image={News2.image.gatsbyImageData} alt={News2.image.title} />
                  <div className="card-image-overlay">
                    <p>{Moment(News2.date).format('ll')}</p>
                    <h3>{News2.title}</h3>
                  </div>
              </div>
              </Link>
            </Col>
            <Col md={4} className="mb-5">
              <Link to={"news/" + News3.slug}>
                <div className="card-image card-image-sm">
                  <GatsbyImage image={News3.image.gatsbyImageData} alt={News3.image.title} />
                  <div className="card-image-overlay">
                    <p>{Moment(News3.date).format('ll')}</p>
                    <h3>{News3.title}</h3>
                  </div>
                </div>
              </Link>
            </Col>            
            <Col md={12} className="text-end">
              <Link to={'/news'} className="btn-link"><Trans>All news</Trans><img src={ArrowRight} alt="arrow-left" /></Link>
            </Col>
          </Row>
        </Container>
      </div>
      {/* /.py-80 */}
    </Layout>
  )
}

export const query = graphql`
query($language: String!){
    allContentfulHomePage (filter: {node_locale: {eq: $language}}) {
      edges {
        node {
          aboutSectionHeading
          heatPowerSectionHeading
          heatPowerText1 {
            heatPowerText1
          }
          heatPowerText2 {
            heatPowerText2
          }
          heatPowerVideoLink
          heroTitle
          heroSubTitle
          howHeading
          howText {
            howText
          }
          whatHeading
          whatText {
            whatText
          }
          whyHeading
          whyText {
            whyText
          }
          topImage {
            gatsbyImageData
            title
          }
        }
      }
    }
    allContentfulProject (sort: {fields: [order], order: DESC}, filter: {node_locale: {eq: $language}}) {
      edges {
        node {
          name
          locationLatlong
          startedText
          finishedText
          slug
          image {
            gatsbyImageData
            title
          }
          order
        }
      }
    }
    allContentfulNews (sort: {fields: [date], order: DESC}, filter: {node_locale: {eq: $language}}){
      edges {
        node {
          image {
            gatsbyImageData
            title
          }
          title
          date
          slug
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
